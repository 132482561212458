@import "variables.scss";

#site-container {//#main-area #content-area {
    footer {
        display: flex;
        align-items: center;
        justify-content: left;
        background-color: $primary;
        width: 100%;
        flex: 0 0 $footer-height;
        padding: 0 20px;
        color: white;
        font-size: 12px;
    }
}
