@import "variables.scss";

#site-container #main-area #sidebar {
    background-color: $primary;
    flex: 0 0 $sidebar-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-right: 2px solid $primary-2;

    header {
        .app-name {
            color: $secondary;
            padding: 32px;
            font-size: 18px;
            font-weight: bold;
        }
    }
    
    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 16px;

        .link {
            width: 100%;
            height: 56px;

            a {
                width: 100%;
                height: 100%;
                text-decoration: none;
                color: $secondary;
                border-bottom: 2px solid transparent;
                transition: $generalTransition;
                padding-left: 32px;
                
                display: flex;
                align-items: center;

                &:hover {
                    color: white;

                    border-bottom: 2px solid white;
                }
            }
        }
    }
}
