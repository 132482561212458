@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
@import "variables.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body, #root {
    font-family: 'Poppins', sans-serif;
    height: 100%;
    background-color: $primary;
    color: white;
}

a {
    color: $accent-blue;
}

.fauxLink {
    color: $accent-blue;
    text-decoration: underline;
    cursor: pointer;
}
