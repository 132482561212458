// Sizing
$navBar-height: 48px;
$footer-height: 48px;
$sidebar-width: 300px;
$border-size: 1px;

// Colours
$primary: #1E1F20;
$primary-2: #252D2A;
$secondary: #8ED761; // brand colour?
$secondary-2: #4E8148;
$accent-blue: #24BBC8;
$accent-pink: #E86180;

// Misc
$generalTransition: 0.2s;
