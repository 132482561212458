@import "variables.scss";

#site-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    // Styling for the main-area (sidebar and content container)
    #main-area {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        // when used as a navbar change the flex direction
        &.navbar {
            flex-direction: column;
        }

        #content-area {
            flex: 1;
            background-color: $primary;
            color: white;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            // Container that wraps site content inside main-area
            main {
                padding: 32px;
                flex: 1 1 100%;
                max-width: 1400px;
            }
        }
    }
}