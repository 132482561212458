@import "variables.scss";

#site-container #main-area #navbar {
    background-color: purple;
    flex: 0 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 2px solid $primary-2;
}
